import { Box, Button } from "@mui/material"
import { useContext, useState } from "react"
import {GetAllProfileRoles, GetUserCustomClaims} from '../../GetProfileData'
import { StatusContext } from "../../../../App"
import { useQuery } from "react-query"
import { aaa, bbb } from "../../ProfileSecurityChanges"
import { StringDecoder } from "string_decoder"
type customClaimType ={
    id: string,
    title: string,
    available_claims: departmentClaimsListType[],
    department: string
}

type departmentClaimsListType ={
    id: string,
    access: number,
    title: string
}
export const ProfileRoles = () => {
    
    const [currentClaims, setCurrentClaims] = useState<customClaimType[]>()
    
    const claimsList = async () => {
        let claimArray: customClaimType[] = []
        let claims = await GetAllProfileRoles()
        let userClaims = await GetUserCustomClaims()
        let userClaimList: string[] = userClaims?.claim as string[]
        console.log(userClaims)
        if(userClaims?.claim !== undefined) {
            userClaimList.forEach((claimId: string) => {
                let department = claimId.substring(0, claimId.indexOf("_"))
                let index = claims.findIndex((x) => x.id == department)
                if(index !== -1) {
                    let innerIndex = claims[index].available_claims.findIndex((y: departmentClaimsListType) => y.id == claimId)
                    let toPush = claims[index].available_claims[innerIndex]
                    toPush.department = claims[index].title
                    claimArray.push(toPush)
                }
            })
        }
        setCurrentClaims(claimArray as customClaimType[])
        console.log(currentClaims)
    }

    const {} = useQuery({
        queryKey: ["custom_claims"],
        queryFn: async () =>{
            await claimsList()
        }
    })
    

    return (
        <Box paddingX={5}>
            <p>{currentClaims ? currentClaims.map((value) => (
                <Box border={1}>
                <h3>{value.title}</h3>
                <p>Ministry: {value.department}</p>
                </Box>
            )) : <></>}</p>
        <h4>You are not currently serving in a Ministry that requires the use of Engrafo.</h4>
        </Box>
    )
}