import React from "react";
import {
  Box,
  Grid,
  Button,
  List,
  ListItem,
  ListItemButton,
  createTheme,
  ThemeProvider,
  Avatar,
  ClickAwayListener,
  ListItemAvatar,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { StatusContext } from "../../../App";
import { EngrafoApps } from "./EngrafoApps";
import { Logout, Settings } from "@mui/icons-material";
import {
  BrandColors,
  BrandType,
} from "../../../Constants";

export const AccountMenu = () => {
  const {currentStatus, setCurrentStatus} = React.useContext(StatusContext);
  const [showAccountMenu, setShowAccountMenu] = React.useState(false);
  const navigate = useNavigate();
  const [loggingOut, setLoggingOut] = React.useState(false);
  const MenuTheme = createTheme({
    components: {
      MuiListItemButton: {
        styleOverrides: {
          root: {
            borderRadius: 10,
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            "&:hover": {
              bgcolor: BrandColors.WHITE
            },
          },
        },
      },
    },
  });

  const handleClickAway = () => {
    setShowAccountMenu(false);
  };

  const handleLogOut = async () => {
    setLoggingOut(true);
    navigate("/logout")
  };

  const handleRedirect = (url: string) => {
    window.location.href = url
  }



  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box>
        <ThemeProvider theme={MenuTheme}>
          <IconButton
            sx={{
              color: BrandColors.WHITE
            }}
            onClick={() => setShowAccountMenu(!showAccountMenu)}
          >
            <Avatar
              src={currentStatus?.profile_picture?.toString()}
              {...stringAvatar(currentStatus?.displayName, null, null)}
            >
              {currentStatus.displayName
                ?.toString()
                .substring(0, 1)
                .toUpperCase()}
            </Avatar>
          </IconButton>
          {showAccountMenu ? (
            <Box
              sx={{
                position: "absolute",
                right: "1vw",
                bgcolor: BrandColors.GRAY.VERY_LIGHT,
                boxShadow: 5,
                width: "27vw",
                minWidth: "300px",
                zIndex: "tooltip",
                maxWidth: "375px",
                borderRadius: 5,
                border: 0.5,
                paddingLeft: "1vh",
                paddingRight: "1vh",
                borderColor: BrandColors.ENGRAFO.LIGHT,
                color: BrandColors.WHITE,
                fontWeight: BrandType._WEIGHT.SEMIBOLD,
                textAlign: "center",
              }}
            >
              <Box
                sx={{
                  marginTop: "1vh",
                  bgcolor: BrandColors.WHITE,
                  borderRadius: 5,
                 
                }}
              >
                <Box
                  sx={{
                    textAlign: "left",
                    paddingLeft: "min(30px, 1.0vw)",
                  }}
                >
                  
                  <Box
                    sx={{
                      justifyContent: "center",
                      alignContent: "center",
                      marginTop: "20px",
                      paddingTop: "20px",
                      paddingBottom: "20px",
                      marginBottom: "20px",
                      color: BrandColors.GRAY.REGULAR,
                      
                    }}
                  >
                    <Grid container direction={"row"} alignContent={"center"} justifyContent="space-between">
                      <Grid item display="inline-flex">
                        <Avatar
                          src={currentStatus?.profile_picture?.toString()}
                          {...stringAvatar(currentStatus?.displayName, null, null)}
                        >
                          {currentStatus?.displayName
                            ?.toString()
                            .substring(0, 1)
                            .toUpperCase()}
                        </Avatar>
                        <Box
                          sx={{
                            display: "inline-block",
                            paddingLeft: '10px',
                            textAlign: "left",
                            alignItems: "center",
                          }}
                        >
                          <Box>{currentStatus.displayName}</Box>
                          <Box
                            sx={{
                              fontWeight: BrandType._WEIGHT.LIGHT,
                              fontSize: "12px",
                            }}
                          >
                            {currentStatus.email}
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item>
                        <IconButton
                          onClick={() =>
                            handleRedirect(
                              "https://identity.fwcpchurch.org/account/profile"
                            )
                          }
                        >
                          <Settings />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  bgcolor: BrandColors.WHITE,
                  color: BrandColors.BROWN,
                  fontWeight: BrandType._WEIGHT.REGULAR,
                  fontSize: "14px",
                  borderRadius: 5,
                  marginTop: "2vh",
                  marginBottom: "2vh",
                }}
              >
                <List
                  sx={{
                    display: "flex",
                    flexFlow: "column wrap",
                    paddingX: "10px",
                    textAlign: "center",
                    height: "210px", // set the height limit to your liking
                    overflow: "auto",
                  }}
                >
                  {EngrafoApps.map((x: any) => (
                    <ListItem sx={{ width: "auto" }} disableGutters>
                      <ListItemButton
                        onClick={() => handleRedirect(x.link)}
                        disabled={!x.active}
                        sx={{
                          display: "block",
                          textAlign: "center",
                        }}
                      >
                        <ListItemAvatar>
                          <img src={x.img} width={"50px"} />
                        </ListItemAvatar>
                        {x.title}
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Box>
              <Box marginTop="2vh">
                <Button
                  sx={{
                    borderBottomLeftRadius: 15,
                    borderBottomRightRadius: 15,
                    color: BrandColors.ENGRAFO_DARK,
                    marginBottom: "1vh",
                  }}

                  startIcon={<Logout />}
                  onClick={handleLogOut}
                  fullWidth
                >
                  Log Out
                </Button>
              </Box>
            </Box>
          ) : (
            <></>
          )}
        </ThemeProvider>
      </Box>
    </ClickAwayListener>
  );
};

export function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatar(name: string | null, widthAndHeight: string | null, fontSize: string | null) {
  return {
    sx: {
      bgcolor:
        typeof name == "string"
          ? stringToColor(name)
          : BrandColors.ENGRAFO_DARK,
      fontWeight: BrandType._WEIGHT.REGULAR,
      minWidth: widthAndHeight? widthAndHeight : "",
      minHeight: widthAndHeight? widthAndHeight : "",
      fontSize: fontSize? fontSize : "",
    },
    children:
      typeof name == "string"
        ? `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
        : "",
  };
}