import React from "react";
import {CircularProgress, Box} from '@mui/material'
export const LoggedInRedirect = () => {
    let params = new URLSearchParams(document.location.search);
    let locationTo = params.get("redirect")
    if(!window.location.hostname.startsWith('localhost')) {
    if(locationTo ) {
        window.location.href = locationTo
    } else {
        window.location.href = "https://engrafo.fwcpchurch.org"
    }
}
    return (
    <>
     <Box>
        <CircularProgress />
     </Box>
    </>
    )
}