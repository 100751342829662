import { LoadingButton } from "@mui/lab"
import { Button, Grid, TextField } from "@mui/material"
import { useState } from "react"
import { ForgotPassword } from "../Status/LoginUser"
import { BrandType } from "../../Constants"

export const ForgotPasswordComponent = ({email, backToLogin}: {email: string, backToLogin: Function}) => {
    const [userInput, setUserInput] = useState(email)
    const [loading, setLoading] = useState(false)
    const [text, setText] = useState('')
    const sendEmail = async () => {
        setLoading(true)
        await ForgotPassword(userInput);
        setLoading(false)
        setText("If the email matches an account with Identity, a password reset email has been sent to that address.")
        setTimeout(() => {
            setText('')
        }, 4000)
    }
    const rowStyle = {
        textAlign: "center",
        width: '400px',
        fontWeight: BrandType._WEIGHT.REGULAR,
        justifyContent: "space-between",
        display: "inline-flex",
      };
    return (
        <Grid container direction='column' gap={2} sx={rowStyle}>
            <Grid item>
                <TextField fullWidth value={userInput} onChange={(e) => setUserInput(e.target.value)} />
            </Grid>
            <Grid item>
                <LoadingButton fullWidth variant="contained" loading={loading} onClick={sendEmail}>Send Password Reset Email</LoadingButton>
            </Grid>
            <Grid item>
                <Button fullWidth onClick={() => backToLogin()}>Back To Login</Button>
            </Grid>
            <Grid item display={text.length > 0 ? 'block' : 'none'}>
                {text}
            </Grid>
        </Grid>
    )
}